import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import logo2 from "./logo2.svg";
import "./Header.css";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar style={{ backgroundColor: "black" }} position="static">
         <Toolbar>
          <img width={50} src={logo2} alt="logo"></img>
          <h2 className="logo" style={{ flexGrow: 1 }}>
            Simply Retainers
          </h2>

          
        </Toolbar> 
      </AppBar>
    </div>
  );
}
