import { BrowserRouter, Route } from "react-router-dom";
import "./App.css";
import ButtonAppBar from "./ButtonAppBar";

import Message from "./Message";
import TrackingCard from "./TrackingCard";

function App() {
  return (
    <BrowserRouter>
      <ButtonAppBar />
      <div className="App">
        {/* <Header /> */}
        <Route exact path="/" component={Message} />
        <Route path="/:id" component={TrackingCard}></Route>

      </div>
    </BrowserRouter>
  );
}

export default App;
