import React from "react";
import { useState, useEffect } from "react";
import "./App.css";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import FolderList from "./FolderList";
import QontoConnector from "./QontoConnector";

const TrackingCard = ({ match }) => {
  const { width, height } = useWindowSize()
  const [status, setStatus] = useState({});
  const [valid, setValid] = useState(true);
  const [phase, setPhase] = useState(0);

  useEffect(() => {
    async function fetchMyAPI() {
      let response = await fetch(`https://track.simplyretainers.com/${match.params.id}`);
      //let response = await fetch(`http://localhost:8080/${match.params.id}`);
      response = await response.json();

      if (response.hasOwnProperty("invalid")) {
        console.log("INVALID!!!!");
        setValid(false);
      }
      console.log(response);
      setStatus(response);
      
      if (response.status === "Your order has shipped!") {
        console.log(phase);
        setPhase(2);
      } else if (response.status === "Manufacturing phase") {
        setPhase(1);
      } else {
        setPhase(0);
        console.log(response.status);
      }
    }

    fetchMyAPI();
  }, []);

  return (
    <div className="container">
      {valid ? (
        <div>
          <h1>
            Hello, {status.name} <span className="wave">👋🏾</span>
          </h1>
          <div className="container">
            <p className="txt">
              Know the status of your order, from the moment your impression kit
              ships to when it leaves our lab for delivery.
            </p>
            <h3>
              Current Status:{" "}
              <span style={{ textDecoration: "lighter" }}>{status.status}</span>
            </h3>

            <QontoConnector phase={phase} />

            <FolderList
              impressionKit={status.impressionKit}
              impressionKitReturn={status.impressionKitReturn}
              completedOrder={status.completedOrder}
            />
          </div>
          {status.status === "Your order has shipped!" && (
          
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={500}
            />
          )}
        </div>
      ) : (
        <p>Invalid URL, please enter the correct URL.</p>
      )}

      
      

      <footer>
        <p></p>
      </footer>
    </div>
  );
};

export default TrackingCard;
