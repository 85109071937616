import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { Icon } from "@iconify/react";
import Divider from "@material-ui/core/Divider";


const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: "0",
    alignItems: "center",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function FolderList({impressionKit, impressionKitReturn, completedOrder}) {
  const classes = useStyles();

  const urlFormat = (number) => {
    const url =
      "https://tools.usps.com/go/TrackConfirmAction.action?tLabels=" + number;
    return url;
  };

 

  return (
    <List className={classes.root}>
      <h1>
        <span>📦</span> Tracking Numbers
      </h1>
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#004B87" }}>
            <Icon
              color="#FFFFFF"
              height="25px"
              width="25px"
              icon="simple-icons:usps"
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Impression Kit"
          secondary={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFormat(impressionKit)}
              >
                {impressionKit}
              </a>
            </>
          }
        />
      </ListItem>
      <Divider />

      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#004B87" }}>
            <Icon
              color="#FFFFFF"
              height="25px"
              width="25px"
              icon="simple-icons:usps"
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Return Impression Kit"
          secondary={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFormat(impressionKitReturn)}
              >
                {impressionKitReturn}
              </a>
            </>
          }
        />
      </ListItem>
      <Divider />
      <ListItem>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: "#004B87" }}>
            <Icon
              color="#FFFFFF"
              height="25px"
              width="25px"
              icon="simple-icons:usps"
            />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary="Completed Order"
          secondary={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={urlFormat(completedOrder)}
              >
                {completedOrder}
              </a>
            </>
          }
        />
      </ListItem>
    </List>
  );
}
