import "./App.css";


const Message = () => {


  return (
    <div>
      <h1>Invalid Tracking URL</h1> 
    </div>
  );
};

export default Message;
